var shelf = (function ($, window, document, undefined) {
    var sweetAlertType = {
        SUCCESS: "success",

        ERROR: "error",

        WARNING: "warning",

        INFO: "info",

        QUESTION: "question",
    };

    // oggetto per creare il toast
    var toastrType = {
        SUCCESS: "success",

        ERROR: "error",

        WARNING: "warning",

        INFO: "info",
    };

    // animazione bottone newsletter e textbox
    var animateButton = function (e) {
        e.preventDefault(); // previene l'azione di default del click
        // resetta l'animazione
        $(e.target).removeClass("animate"); // rimuove la classe animate

        // aggiunge la classe animate con un ritardo di 10 millisecondi
        setTimeout(function () {
            $(e.target).addClass("animate"); // aggiunge la classe animate
        }, 10);

        // rimuove la classe animate dopo 6 secondi
        setTimeout(function () {
            $(e.target).removeClass("animate"); // rimuove la classe animate
        }, 2500);
    };

    // funzione per aprire il programma di posta e creare un nuovo messaggio facendo click sull'elemento
    function apriPostaElettronica(
        field,
        mailto = "mailto:shelf@outlook.it",
        message = "scrivi una mail"
    ) {
        $(field).click(function () {
            var textToCopy = $(field).text();

            window.location.href = mailto;

            $(field).fadeOut(500);
            setTimeout(function () {
                $(field).html(message);
                $(field).fadeIn(200);
                setTimeout(function () {
                    $(field).fadeOut(500);
                    setTimeout(function () {
                        $(field).html(textToCopy);
                        $(field).fadeIn(500);
                    }, 500);
                }, 500);
            }, 500);
        });
    }

    // function showNotification(object, message) {
    //     const container = document.getElementById('notification-container');

    //     const notification = document.createElement('div');
    //     notification.classList.add('notification');

    //     // Aggiungi l'oggetto (titolo)
    //     const notificationTitle = document.createElement('div');
    //     notificationTitle.classList.add('notification-title');
    //     notificationTitle.innerText = object;

    //     // Aggiungi il messaggio
    //     const notificationMessage = document.createElement('div');
    //     notificationMessage.classList.add('notification-message');
    //     notificationMessage.innerText = message;

    //     // Aggiungi il bottone per chiudere la notifica
    //     const closeButton = document.createElement('button');
    //     closeButton.innerHTML = 'X';
    //     closeButton.classList.add('notification-close');
    //     closeButton.onclick = () => {
    //         notification.remove();
    //     };

    //     // Aggiungi titolo, messaggio e bottone alla notifica
    //     notification.appendChild(notificationTitle);
    //     notification.appendChild(notificationMessage);
    //     notification.appendChild(closeButton);

    //     // Aggiungi la notifica al container
    //     container.appendChild(notification);

    //     // Funzione per rimuovere la notifica
    //     const removeNotification = () => {
    //         notification.classList.add('hide');
    //         setTimeout(() => {
    //             notification.remove();
    //         }, 500); // tempo per l'animazione di scomparsa
    //     };

    //     // Dopo 5 secondi, nascondi la notifica e rimuovila (se non è in hover)
    //     let timeout = setTimeout(removeNotification, 5000);

    //     // Blocca la scomparsa della notifica durante l'hover
    //     notification.onmouseover = () => {
    //         clearTimeout(timeout);
    //     };

    //     // Riavvia il timer quando l'utente esce dall'hover
    //     notification.onmouseout = () => {
    //         timeout = setTimeout(removeNotification, 5000);
    //     };
    // }




    // funzione per copiare il testo facendo click sull'elemento

    function showNotification(object, message) {
        const container = document.getElementById('notification-container');

        const notification = document.createElement('div');
        notification.classList.add('notification');

        // Aggiungi l'oggetto (titolo)
        const notificationTitle = document.createElement('div');
        notificationTitle.classList.add('notification-title');
        notificationTitle.innerHTML = object; // Usa innerHTML per interpretare l'HTML

        // Aggiungi il messaggio
        const notificationMessage = document.createElement('div');
        notificationMessage.classList.add('notification-message');
        notificationMessage.innerHTML = message; // Usa innerHTML per il messaggio

        // Aggiungi il bottone per chiudere la notifica
        const closeButton = document.createElement('button');
        closeButton.innerHTML = 'X';
        closeButton.classList.add('notification-close');
        closeButton.onclick = () => {
            notification.remove();
        };

        // Aggiungi titolo, messaggio e bottone alla notifica
        notification.appendChild(notificationTitle);
        notification.appendChild(notificationMessage);
        notification.appendChild(closeButton);

        // Aggiungi la notifica al container
        container.appendChild(notification);

        // Funzione per rimuovere la notifica
        const removeNotification = () => {
            notification.classList.add('hide');
            setTimeout(() => {
                notification.remove();
            }, 500); // tempo per l'animazione di scomparsa
        };

        // Dopo 5 secondi, nascondi la notifica e rimuovila (se non è in hover)
        let timeout = setTimeout(removeNotification, 5000);

        // Blocca la scomparsa della notifica durante l'hover
        notification.onmouseover = () => {
            clearTimeout(timeout);
        };

        // Riavvia il timer quando l'utente esce dall'hover
        notification.onmouseout = () => {
            timeout = setTimeout(removeNotification, 5000);
        };
    }



    function copyToClipboard(field, message = "testo copiato") {
        $(field).click(function () {
            var textToCopy = $(field).text();
            var tempTextarea = $("<textarea>");
            $("body").append(tempTextarea);
            tempTextarea.val(textToCopy).select();
            document.execCommand("copy");
            tempTextarea.remove();

            $(field).fadeOut(500);
            setTimeout(function () {
                $(field).html(message);
                $(field).fadeIn(200);
                setTimeout(function () {
                    $(field).fadeOut(500);
                    setTimeout(function () {
                        $(field).html(textToCopy);
                        $(field).fadeIn(500);
                    }, 500);
                }, 500);
            }, 500);
        });
    }

    // formatta la data in formato leggibile quando la
    // recupero dal DB
    function formatDate(date, withTime = false) {
        if (date == null || date == false) {
            return "--";
        }

        var array = date.split(" ");

        var d = array[0];

        var t = array[1];

        var dTempValue = d.split("-");

        var string =
            dTempValue[2] +
            "/" +
            pad(dTempValue[1], 2) +
            "/" +
            pad(dTempValue[0], 2);

        if (withTime != false) {
            string += " " + t;
        }

        return string;
    }

    // funzione per aggiungere caratte a sinistra o destra in modo che
    // la stringa sia sempre di una certa lunghezza
    function pad(str, max) {
        str = str.toString();

        return str.length < max ? pad("0" + str, max) : str;
    }

    function createToast(
        type,
        message,
        // position = "toast-top-center",
        position = "toast-bottom-right",
        closeButton = true
    ) {
        toastr.options = {
            closeButton: true,

            debug: false,

            newestOnTop: false,

            progressBar: true,

            positionClass: position,

            preventDuplicates: true,

            onclick: null,

            showDuration: "300",

            hideDuration: "1000",

            timeOut: 1000,

            extendedTimeOut: 0,

            showEasing: "swing",

            hideEasing: "linear",

            showMethod: "fadeIn",

            hideMethod: "fadeOut",

            tapToDismiss: false,
        };

        if (type == "error") {
            toastr.error(message);
        } else if (type == "success") {
            toastr.success(message);
        } else if (type == "warning") {
            toastr.warning(message);
        } else if (type == "info") {
            toastr.info(message);
        }
    }

    // Funzione per validare l'indirizzo email
    function validaEmail(email) {
        var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+(com|it|net|org|edu|gov|mil|co\.uk)))$/;
        return re.test(String(email).toLowerCase());
    }

    // Chiate ajax GET e POST
    function post(
        _url,
        method = "POST",
        complete,
        success = null,
        error = null,
        _params = {},
        timeOut = null
    ) {
        $.ajax({
            url: _url,

            method: method,

            data: _params,

            success: success,

            complete: complete,

            error: error,

            timeout: timeOut,
        });
    }

    function get(
        _url,
        complete,
        success = null,
        error = null,
        _params = {},
        timeOut = null
    ) {
        $.ajax({
            url: _url,

            method: "GET",

            data: _params,

            success: success,

            complete: complete,

            error: error,

            timeout: timeOut,
        });
    }

    function showPasswordText(iconId, inputId) {
        $(iconId).on("click", function () {
            if ($(iconId).hasClass("bi-eye-slash")) {
                $(inputId).attr("type", "text");
                $(iconId).removeClass("bi-eye-slash");
                $(iconId).addClass("bi-eye");
            } else {
                $(inputId).attr("type", "password");
                $(iconId).removeClass("bi-eye");
                $(iconId).addClass("bi-eye-slash");
            }
        });
    }

    function createOverlayWindow(
        url,
        id_window,
        title = "",
        calback,
        position = "",
        type = "mediumModal"
    ) {
        var modalOptions = {};
        switch (type) {
            case "smallModal":
                modalOptions.id = "#smallModal";
                modalOptions.title = "#smallTitle";
                modalOptions.body = "#smallBody";
                modalOptions.position = "#smallModalDialog";
                break;
            case "mediumModal":
                modalOptions.id = "#mediumModal";
                modalOptions.title = "#mediumTitle";
                modalOptions.body = "#mediumBody";
                modalOptions.position = "#mediumModalDialog";
                break;
            case "largeModal":
                modalOptions.id = "#largeModal";
                modalOptions.title = "#largeTitle";
                modalOptions.body = "#largeBody";
                modalOptions.position = "#largeModalDialog";
                break;
        }

        $.ajax({
            url: url,
            beforeSend: function () {
                $("#loader").show();
            },
            success: function (result) {
                var options = {
                    backdrop: "static",
                    keyboard: false,
                };
                $(modalOptions.title).html(title);
                $(modalOptions.body).html(result);
                $(modalOptions.position).addClass(position);
                const myModal = new bootstrap.Modal(
                    $(modalOptions.id),
                    options
                );
                myModal.show();
                calback(id_window);
            },

            complete: function () {
                $("#loader").hide();
            },
            error: function (jqXHR, testStatus, error) {
                alert("Page " + url + " cannot open. Error:" + error);
                // $('#loader').hide();
            },
            timeout: 8000,
        });
    }

    function verifyUserAuthorization(
        role = null,
        mod = true,
        _callback = null
    ) {
        shelf.get(
            "/verifyUserAuthorization/" + role + "/" + mod,
            null,
            function (response) {
                if (response.result === "success") {
                    if (_callback != null && typeof _callback == "function") {
                        _callback(response);
                    }
                } else {
                    shelf.createToast(
                        shelf.toastrType.WARNING,
                        "Non sei autorizzato a compiere questa azione!",
                        'toast-bottom-right'
                    );
                    return;
                }
            },
            function (xhr, status, error) {
                shelf.createToast(shelf.toastrType.ERROR, error.message, 'toast-bottom-right');
            }
        );
    }

    function createAutoComplete(url, inputId, dropdownId, digitNumberStart = 3, _callback) {
        $("#" + inputId).on("keyup", function () {
            // Ottieni il valore dell'input
            var val = $("#" + inputId).val();

            // Verifica se sono stati digitati almeno 3 caratteri
            if (val.length >= digitNumberStart) {
                // Avvia la chiamata AJAX solo se ci sono almeno 3 caratteri
                fetch(url + val)
                    .then((response) => response.json())
                    .then((data) => {
                        // Visualizza i risultati dell'autocompletamento solo se ci sono dati
                        if (data.length > 0) {
                            $("#" + dropdownId).addClass("show");
                            _callback(data)
                        } else {
                            // Se non ci sono corrispondenze, rimuovi la classe 'show'
                            $("#" + dropdownId).removeClass("show");
                        }
                    })
                    .catch((error) => {
                        console.error(
                            "Errore durante la richiesta Ajax:",
                            error
                        );
                        // Rimuovi la classe 'show' in caso di errore
                        $("#" + dropdownId).removeClass("show");
                    });
            } else {
                // Se ci sono meno di 3 caratteri, rimuovi la classe 'show'
                $("#" + dropdownId).removeClass("show");
            }
        });


        $("#" + inputId).on("change", function () {
            // Itera su tutti gli elementi con la classe '.dropdown-item' all'interno di '#autocompleteDropdown'
            $("#" + inputId + " .dropdown-item").each(function (i, ele) {
                var text = $(ele).text();
                if (text == $("#" + inputId).val()) {
                    $(ele).trigger("click");
                }
            });
        });

        $(document).on("click", function (event) {
            // Controlla se l'evento di clic è avvenuto all'interno dell'input o del dropdown
            if (
                !$(event.target).closest("#" + inputId, "#" + dropdownId).length
            ) {
                // Rimuovi la classe 'show' quando si clicca al di fuori
                $("#" + dropdownId).removeClass("show");
            }
        });

    }

    function createAutoComplete2(url, inputId, dropdownId, digitNumberStart = 3, _callback) {
        $("#" + inputId).on("keyup", function () {
            // Ottieni il valore dell'input
            var val = $("#" + inputId).val();
            var idUser = $("#" + inputId).data('id-user');
            // Verifica se sono stati digitati almeno 3 caratteri
            if (val.length >= digitNumberStart) {
                // Avvia la chiamata AJAX solo se ci sono almeno 3 caratteri
                fetch(url + val + '/' + idUser)
                    .then((response) => response.json())
                    .then((data) => {
                        // Visualizza i risultati dell'autocompletamento solo se ci sono dati
                        if (data.length > 0) {
                            $("#" + dropdownId).addClass("show");
                            _callback(data)
                        } else {
                            // Se non ci sono corrispondenze, rimuovi la classe 'show'
                            $("#" + dropdownId).removeClass("show");
                        }
                    })
                    .catch((error) => {
                        console.error(
                            "Errore durante la richiesta Ajax:",
                            error
                        );
                        // Rimuovi la classe 'show' in caso di errore
                        $("#" + dropdownId).removeClass("show");
                    });
            } else {
                // Se ci sono meno di 3 caratteri, rimuovi la classe 'show'
                $("#" + dropdownId).removeClass("show");
            }
        });


        $("#" + inputId).on("change", function () {
            // Itera su tutti gli elementi con la classe '.dropdown-item' all'interno di '#autocompleteDropdown'
            $("#" + inputId + " .dropdown-item").each(function (i, ele) {
                var text = $(ele).text();
                if (text == $("#" + inputId).val()) {
                    $(ele).trigger("click");
                }
            });
        });

        $(document).on("click", function (event) {
            // Controlla se l'evento di clic è avvenuto all'interno dell'input o del dropdown
            if (
                !$(event.target).closest("#" + inputId, "#" + dropdownId).length
            ) {
                // Rimuovi la classe 'show' quando si clicca al di fuori
                $("#" + dropdownId).removeClass("show");
            }
        });

    }

    function createAutoComplete3(url, inputId, dropdownId, digitNumberStart = 3, _callback) {
        $("#" + inputId).on("keyup", function () {
            // Ottieni il valore dell'input
            var val = $("#" + inputId).val();

            // Verifica se sono stati digitati almeno 3 caratteri
            if (val.length >= digitNumberStart) {
                // Avvia la chiamata AJAX solo se ci sono almeno 3 caratteri
                fetch(url + val)
                    .then((response) => response.json())
                    .then((data) => {
                        // Visualizza i risultati dell'autocompletamento solo se ci sono dati
                        if (data.length > 0) {
                            $("#" + dropdownId).addClass("show");
                            _callback(data);
                        } else {
                            // Se non ci sono corrispondenze, rimuovi la classe 'show'
                            $("#" + dropdownId).removeClass("show");
                        }
                    })
                    .catch((error) => {
                        console.error("Errore durante la richiesta Ajax:", error);
                        // Rimuovi la classe 'show' in caso di errore
                        $("#" + dropdownId).removeClass("show");
                    });
            } else {
                // Se ci sono meno di 3 caratteri, rimuovi la classe 'show'
                $("#" + dropdownId).removeClass("show");
            }
        });

        $(document).on("click", function (event) {
            // Controlla se l'evento di clic è avvenuto all'interno dell'input o del dropdown
            if (!$(event.target).closest("#" + inputId + ", #" + dropdownId).length) {
                $("#" + dropdownId).removeClass("show");
            }
        });
    }


    // funzione generica per la creazione dei caroselli
    function createSwiperCarousel(classSwiper) {
        return new Swiper(classSwiper, {
            loop: false,
            parallax: true,
            mousewheel: {
                forceToAxis: true,
                sensitivity: 1,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                dynamicBullets: true,
            },
            grabCursor: true,
            slidesPerView: 3,
            spaceBetween: 0,
            // slidesPerView: "auto",
            speed: 600,
            // modules: [EffectMaterial],
            // // specify "material" effect
            // effect: 'material',
            // // material effect parameters
            // materialEffect: {
            //     slideSplitRatio: 0.65,
            // },
            // mousewheel: true,
            centeredSlides: false,
            // freeMode: false,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    // freeMode: false,
                    centeredSlides: true,
                },
                540: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                1339: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                },
                1440: {
                    slidesPerView: 5,
                    spaceBetween: 0,
                },
            },
        });

    };

    var _return = {
        apriPostaElettronica: apriPostaElettronica,
        copyToClipboard: copyToClipboard,
        formatDate: formatDate,
        pad: pad,
        toastrType: toastrType,
        createToast: createToast,
        validaEmail: validaEmail,
        animateButton: animateButton,
        get: get,
        post: post,
        showPasswordText: showPasswordText,
        createOverlayWindow: createOverlayWindow,
        verifyUserAuthorization: verifyUserAuthorization,
        createAutoComplete: createAutoComplete,
        createAutoComplete2: createAutoComplete2,
        createAutoComplete3: createAutoComplete3,
        createSwiperCarousel: createSwiperCarousel,
        showNotification: showNotification
    };

    return _return;
})(jQuery, window, document);

window.shelf = shelf;
